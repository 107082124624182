<template>
  <div class="tape-controller">
    <FeedItem :drop-items="ultraItems" is-ultra />
    <FeedItem :drop-items="liveItems" @mouseenter.passive="isPaused = true" @mouseleave.passive="isPaused = false" />
  </div>
</template>

<script setup lang="ts">
import { useFeedStore } from '~/project-layers/cs/features/live-drop/store/feedStore';

const feedStore = useFeedStore();

const { liveItems, ultraItems, isPaused } = storeToRefs(feedStore);
</script>

<style lang="scss" src="./FeedController.scss"></style>
