import { WEBSOCKET_FIRST_CHANNEL } from '~/constants/app.constants';
import { parseWSData } from '~/utils/liveDrop/parseWSData';
import type {
  ILiveDropGetParams,
  ILiveDropWSResponse,
} from '~/project-layers/cs/features/live-drop/api/types/client.types';
import type { ITopLiveDropItem } from '~/utils/liveDrop/parseWSData.types';
import { useAlertStore } from '~/store/alert/alert.store';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import type { TPossibleNull } from '~/types/Shared.types';
import { LiveDropRepository } from '~/project-layers/cs/features/live-drop/api/repository/repository';

const WEBSOCKET_EVENTS = ['lastwinner'];
export const MAX_ELEMENTS = 20;

export const useFeedStore = defineStore('feedStore/general', () => {
  const { $api } = useNuxtApp();

  const LIVE_ITEMS_MAX_LENGTH = 50;

  const alertStore = useAlertStore();

  const isLoaded = ref(false);
  const liveItems = ref<ITopLiveDropItem[]>([]);
  const ultraItems = ref<ITopLiveDropItem[]>([]);
  // const uniqueItems = ref<ITopLiveDropItem[]>([])
  const isPaused = ref(false);

  const fetch = async (params: ILiveDropGetParams = { isUltraRare: false }) => {
    try {
      const data = await LiveDropRepository.fetch(params);
      const toAdd = params.isUltraRare ? ultraItems : liveItems;
      toAdd.value = data.slice(0, LIVE_ITEMS_MAX_LENGTH);
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    }
  };

  const getInitData = async () => {
    isLoaded.value = false;

    try {
      await Promise.all([fetch(), fetch({ isUltraRare: true })]);
    } catch {
    } finally {
      isLoaded.value = true;
    }
  };

  let unsubscribe: TPossibleNull<() => void> = null;

  const subscribe = () => {
    unsubscribe = $api.websocket.subscribe(WEBSOCKET_FIRST_CHANNEL, {
      cb: wsCallback,
      event: WEBSOCKET_EVENTS,
      uniqueKey: 'LiveDrops/CSDropItems',
    });
  };

  const wsCallback = (data: ILiveDropWSResponse) => {
    if (isPaused.value) return;
    const parsedMsg = parseWSData(JSON.parse(data.msg), true);

    const find = liveItems.value.find((item) => item.inventoryId?.toString() === parsedMsg.id);
    if (find) return;

    liveItems.value.unshift(parsedMsg);
    if (liveItems.value.length > LIVE_ITEMS_MAX_LENGTH)
      liveItems.value = liveItems.value.slice(0, LIVE_ITEMS_MAX_LENGTH);
  };

  const handleMultiHover = (item: ITopLiveDropItem, hover = true) => {
    if (!item.isMulticastOrMultifix) return;
    liveItems.value.forEach(
      (itemData) => (itemData.hasMultiHover = itemData.gameName === item.gameName ? hover : false),
    );
  };

  return {
    fetch,
    getInitData,
    subscribe,
    handleMultiHover,
    unsubscribe,
    liveItems,
    ultraItems,
    isPaused,
    isLoaded,
  };
});
