import type { ITopLiveDropApiData, ITopLiveDropItem } from '~/utils/liveDrop/parseWSData.types';
import { getDropType, getModificatorOfItem, getRarity, isMulticastOrMultifix } from '~/utils/liveDrop/parseWSData';

export const transform = (apiData: ITopLiveDropApiData[], isUltraRare = false): ITopLiveDropItem[] => {
  return apiData.map(({ itemData, additionalData, userData, caseData, inventoryId }) => ({
    id: itemData.id,
    images: {
      default: itemData.image,
      hover: itemData.imageHover,
    },
    isUltraRare,
    modificator: getModificatorOfItem(additionalData?.percent, getDropType(itemData.gameName, itemData.caseType)),
    rarity: getRarity(itemData.quality),
    quality: itemData.quality,
    user: {
      ...userData,
      nickname: userData.name,
    },
    case: {
      ...caseData,
      name: caseData?.caseName,
      price: caseData?.price,
      type: caseData?.caseType,
    },
    additional: {
      ...additionalData,
      percent: additionalData?.percent,
    },
    isMulticastOrMultifix: isMulticastOrMultifix(itemData.caseType),
    gameName: itemData.gameName,
    type: itemData.type,
    name: itemData.name,
    price: itemData.price,
    inventoryId,
  }));
};
